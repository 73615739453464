/* ------ ELEMENTOS HTML------ */
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');

nav {
  background: linear-gradient(to right, white, #990099);
  text-align: center;
}

.navbar-nav {
  font-family: 'Calibri', sans-serif;
}

a {
  text-align: center;
}

b {
  color: black;
}

/* b:hover{
    A IMPLEMENTAR EN UN FUTURO
    background-color: yellow;
} */

/* ------ ID´S ------*/
#navbarScrollingDropdown {
  color: black;
  font-weight: bold;
}

/* ------ CLASES ------ */
.container-fluid {
  padding: 0px !important;
}

.navbar {
  padding: 0px !important;
}

.navbar-brand {
  width: 10%;
  padding-bottom: 0px;
}

.logo {
  width: 40%;
}

@media only screen and (max-width: 991.2px) {
  .logo {
    width: 100%;
  }

  .navbar-brand {
  width: 60px;
  padding-bottom: 0px;
}

  .nav-link {
    border-bottom: 1px solid black;
  }
}
