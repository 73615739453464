@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');

form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}

textarea{
  background-color: yellow;
}

.ui.form textarea {
  margin-left: 15px !important;
}
.contacto {
  text-align: center;
  margin: 20px 15px;
}

.content {
  padding-bottom: 10%;
}

.contact {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 5px;
  max-width: 100%;
  align-items: stretch;
  justify-content: space-evenly;
  align-content: stretch;
}

.telefonoIcono {
  width: 20px;
}

.textoInformativo {
  text-align: center;
  margin-top: 15px;
}

.telefonoConjunto {
  display: flex;
  justify-content: center;
}

.botonContacto {
  background: #b546b5 !important;
  display: flex;
  align-items: center;
}

button {
  font-family: inherit;
  font-size: 20px;

  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;

  border: none;
  border-radius: 16px !important;
  overflow: hidden;
  transition: all 0.2s;
}

button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

button:hover span {
  transform: translateX(5em);
}

button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    margin-top: 15px !important;
  }

  .telefono {
    text-align: center;
    float: left;
    clear: right;
  }

  .telefonoIcono {
    width: 5%;
  }

  .contact {
    display: grid;
    grid-template-columns: 100%;
    gap: 5px;
    max-width: 100%;
    align-items: stretch;
    justify-content: space-evenly;
    align-content: stretch;
  }

  .disponibilidad {
    text-align: center;
  }
}
