@import url("https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap");

p {
  text-align: left;
  margin: 10px;
}

.tituloQuienesSomos {
  font-family: "Calibri", sans-serif;
  text-align: center;
  font-style: oblique;
  text-shadow: 3px 3px violet;
  letter-spacing: 5px;
}

.logo-cover3 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  width: 100%;
}

.logo-cover {
  display: grid;
  width: 100%;
}

.logoCampaña {
  justify-self: center;
  width: 200px;
  margin-bottom: 15px;
}

.content {
  padding-bottom: 10%;
}
