.carousel,
.carousel-inner > .carousel-item > img {
  height: 20vh;
}

.imgCarrusel {
  object-fit: contain;
  width: 50%;
  position: relative;
  margin: auto;
  margin-top: 10px;
}

.carousel-indicators {
  display: none !important;
}
