@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');
.cabecera {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2%;
  margin-right: 4%;
}

.col-sm {
  margin-bottom: 2%;
}

.titulo {
  font-weight: bold;
  text-align: center;
  font-family: "Calibri", sans-serif !important;
}

.sub {
  font-style: italic;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}
.col1 {
  display: grid;
}
.col2 {
  display: block !important;
}

.col3 {
  display: initial;
}

.content {
  padding-bottom: 10%;
}

@media screen {
  .avatar {
    width: 80%;
    height: 80%;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }

  .todosCancer {
    width: 70%;
    display: block;
    margin: auto;
  }
}
