@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');

p {
  font-family: "ABeeZee", sans-serif;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

li{
  font-family: "ABeeZee", sans-serif;
}

body {
  padding-bottom: 100%;
}

.content {
  margin-bottom: 10%;
}

h1 {
  font-family: "Calibri", sans-serif;
}
