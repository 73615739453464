.card {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex !important;
}

.card-body {
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.container {
  width: 100%;
  height: auto;
  text-align: center;
  padding-bottom: 50px;
}

.card-img-top {
  height: 75%;
}

.img1 {
  width: 100%;
  height: auto;
}

.card-flip {
  display: inline-block;
  padding-right: 40px;
}

.zoom-effect {
  transition: transform 0.5s ease;
}

.zoom-effect:hover {
  transform: scale(1.2);
}
