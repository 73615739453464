@import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;700&display=swap');

h1 {
  margin-top: 10px !important;
}
li{
  margin-bottom: 15px;
}

.tituloCampaña {
  font-family: "Calibri", sans-serif;
  text-transform: uppercase;
  margin-top: 15px;
  text-align: center;
  font-weight: bold;
}

.subtituloCampaña {
  font-family: "Calibri", sans-serif;
  font-weight: bold;
}

.parrafoCampaña {
  text-align: justify;
  margin-left: 15px !important;
}

    li::before {
      content: "🎗️";
      display: inline-block;
      margin-right: 0.5em; /* Espacio entre el emoji y el texto del elemento de la lista */
    }

.textoAgradecimiento {
  text-align: center;
}

.logo-cover2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .content {
    padding-bottom: 50px;
  }
}
